'use strict';

import {OrbitControls} from "three/addons/controls/OrbitControls.js";
import {TrackballControls} from 'three/addons/controls/TrackballControls';
import {ResponseConverter} from "./converter/ResponseConverter.js";
import {Renderer} from "./services/Renderer.js";
import {CameraFactory} from "./services/CameraFactory.js";
import {SceneFactory} from "./services/SceneFactory.js";
import {LightComposer} from "./services/LightComposer.js";
import {ModelGenerator} from "./services/ModelGenerator.js";
import {MeshFactory} from "./services/MeshFactory.js";
import {SquareHelper} from "./models/SquareHelper.js";
import {GUI} from "dat.gui";
import {Raycaster, Vector2} from "three";
import {ColorLegendCreator} from "./services/ColorLegendCreator.js";
import {SolutionFactory} from "./services/SolutionFactory.js";
import {SolutionColorCalculator} from "./services/SolutionColorCalculator.js";
import {MapRenderer} from './services/mapRenderer.js';

// Throttle utility function
function throttle(func, limit) {
    let lastFunc;
    let lastRan;
    return function(...args) {
        if (!lastRan) {
            func.apply(this, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(this, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}

export class Visualizer
{
    _geometryHash = 0;
    _width = 0;
    _height = 0;
    _container = '';
    _modelRenderer = null;
    _mapRenderer = null;
    _sceneLightComposer = null;
    _controls = null;
    _camera = null;
    _scene = null;
    _menuOptions = {};
    _raycaster = null;
    _pointer = null;
    _modelGenerator = null;
    _model = null;
    _meshEdges = null;
    _geometryEdges = null;
    _legend = false;
    _squareHelper = null;
    _hoveredSrf = null;
    _selection_allowed = false;
    _viewPort = null;
    _meshFactory = null;
    _meshObject = null;
    _solutionFactory = null;
    _solutionObject = null;
    _selection = [];
    _shiftPressed = false;
    _solutionMode = false;
    _confirm_selection_btn = null

    // save camera position 
    _initialCameraPosition = null;
    _initialCameraRotation = null;
    _initialCameraQuaternion = null;
    _initialControlsTarget = null;
    
    _fileType = null;

    _visualizationObjects = new Set();

    constructor() {
        this._highlightSelectionThrottle = throttle(this._highlightSelection.bind(this), 100);
        this.responseConverters = new ResponseConverter();
        this._meshFactory = new MeshFactory();
        this._modelGenerator = new ModelGenerator();
        this._solutionFactory = new SolutionFactory();
        this._colorLegendCreator = new ColorLegendCreator();
        this._solutionColorCalculator = new SolutionColorCalculator();
        this.listeners = {};
        this.send_selection_enabled = true;
        this._init();
    }

    _init_scene() {
        const SceneBuilder = new SceneFactory();
        this._scene = SceneBuilder.getSceneObject();
        this._sceneLightComposer = new LightComposer();

        this._sceneLightComposer.generateLightSources(this._scene, this._camera);

        this._controls = new TrackballControls(this._camera, this._modelRenderer.getDomElement());

        this._controls.rotateSpeed = 5.0;
        this._controls.zoomSpeed = 1.5;
        this._controls.panSpeed = 2;
        this._controls.staticMoving = true;
        this._controls.dynamicDampingFactor = 1;

        this._initOverlays();

        this._modelRenderer.setCamera(this._camera); 
        this._modelRenderer.setScene(this._scene);
        this._modelRenderer.setControls(this._controls);
    }

    _addSquareHelper() {
        this._squareHelper = new SquareHelper();
        this._scene.add(this._squareHelper.getModel());
    }

    _initOverlays(){
        this._addOverlays();

        this._raycaster = new Raycaster();
        this._pointer = new Vector2();

        this._modelRenderer.setRaycaster(this._raycaster);
        this._modelRenderer.setPointer(this._pointer);
    }

    _init() {
        this._container = 'threejs-viewport';
        this._initViewport();
        this._modelRenderer = new Renderer(this._width, this._height, this._container);

        const CameraBuilder = new CameraFactory(this._width, this._height);

        this._camera = CameraBuilder.getCameraObject();

        this._init_scene();

        this._initMap();

        this._setBaseCameraPositionParams();


        this._viewPort.addEventListener('click', (event) => {
            this._selectPlane();
        });

        this._viewPort.addEventListener('mousemove', throttle(this._onPointerMove.bind(this), 50));
    }


    _initMap(){
        this._mapRenderer = new MapRenderer(this._container); 
        this._mapRenderer.initCamera();
        this._mapRenderer.initScene();
        
        this._mapRenderer.setMeshCamera(this._camera);
        this._mapRenderer.setMeshControls(this._controls);
        this._mapRenderer.addControls();

        this._mapRenderer.addBox();

        this._mapRenderer.addTransformControls();

    }

    _setBaseCameraPositionParams() {
        this.initialCameraPosition = this._camera.position.clone();
        this.initialCameraRotation = this._camera.rotation.clone();
        this.initialCameraQuaternion = this._camera.quaternion.clone();
        this.initialControlsTarget = this._controls.target.clone();
    }

    _baseCameraPosition() {

        this._camera.position.copy(this.initialCameraPosition);
        this._camera.rotation.copy(this.initialCameraRotation);
        this._camera.quaternion.copy(this.initialCameraQuaternion);
        this._controls.target.copy(this.initialControlsTarget);
        this._controls.update();

    }

    _initViewport()
    {
        const containerElementDimensions = document.getElementsByClassName('main-container__three section')[0].getBoundingClientRect();
        this._width = containerElementDimensions.width;
        this._height = containerElementDimensions.height;
    }

    update_show_file(file_data) {
        this._geometryHash = file_data.hash;
        // Clear cache and reset selections
        this._clearCache();
        this._resetSelection();

        // // Clear the scene before adding new objects
        this._clearVisualizationObjects();

        this._fileType = file_data.file_type;

        if (file_data.file_type === "geometry") {
            this._useGeometryMode(file_data);
        }
        if (file_data.file_type === "mesh") {
            this._useMeshMode(file_data);
        }
        if (file_data.file_type === "solution") {
            this._useSolutionMode(file_data);
        }
    }

    updateSize(callback)
    {
        this._initViewport();
        this._camera.aspect = this._width / this._height;
        this._camera.updateProjectionMatrix();
        this._modelRenderer.setSize(this._width, this._height);

        requestAnimationFrame(callback);
    }

    setAnimationCallback(callback)
    {
        this._modelRenderer.setAnimationCallback(callback);
    }

    run() {
        this._meshObject = this._meshFactory.createEmptyMesh();
        this._modelGenerator.generateMeshFromRawData(this._meshObject);
        this._enableModel(true);

        this._modelRenderer.run();
        
        this._solutionMode = false;
        this._disableMeshEdges();
        this._disableLegend();
    }

    _showObject(response) {
        try {
            this._initOverlays();
            this._addSquareHelper();

            this._modelRenderer.setScene(this._scene);
            const modelmesh = this.__convertResponse(response);
            this._meshObject = this._meshFactory.createMeshModelFromData(modelmesh);
            this._modelGenerator.generateMeshFromRawData(this._meshObject);
            this._enableModel(true);
            this._modelRenderer.setMesh(this._model);
            this._modelRenderer.run();
            this._baseCameraPosition(this._camera);
            this._mapRenderer.hideAxiosArrow(true); 
            this._mapRenderer.addAxisLabel();
            this._mapRenderer.syncCameraAndAxis();

        } catch (error) {
            console.error('Failed to show object:', error); 
        }
    }

    __convertResponse(response)
    {
        return this.responseConverters.convert(response);
    }

    _useGeometryMode(data)
    {
        this._selection_allowed = true;
        this._disableGeometryEdges()
        this._disableMeshEdges();
        this._showObject(data);
        this._solutionMode = false;
        this._enableGeometryEdges()
        this._enableMeshEdges();
        this._disableLegend();
    }

    _useMeshMode(data)
    {
        this._selection_allowed = false;
        this._disableGeometryEdges()
        this._disableMeshEdges();
        this._showObject(data);
        this._solutionMode = false;
        this._enableMeshEdges();
        this._disableLegend();
    }

    _useSolutionMode(data)
    {
        this._selection_allowed = false;
        this._disableGeometryEdges()
        this._disableMeshEdges();
        this._showObject(data.mesh);
        this._solutionMode = true;
        this._enableMeshEdges();
        let solutionModel = this.__convertResponse(data);
        this._solutionObject = this._solutionFactory.createSolutionModelFromData(solutionModel);
        const colorMap = this._solutionColorCalculator.calculateColorMap(this._solutionObject);
        const solutionColorMap = this._modelGenerator.generateColorFromIndex(colorMap.getColorMapAsArray(), this._meshObject.triangles);
        this._modelGenerator.recolorFromColorMap(solutionColorMap);
        this._enableLegend();
    }

    _enableModel(refresh = false)
    {
        if (refresh === true) {
            this._disableModel();
        } 
    
        if (this._model === null) {
            this._model = this._modelGenerator.getModel();
            this._scene.add(this._model);
            this._visualizationObjects.add(this._model.id);
            this._mapRenderer.setMesh(this._model);
        }
    }

    _disableModel()
    { 
        if (this._model !== null) {
            this._scene.remove(this._model);
            this._model = null;
            this._mapRenderer.hideAxiosArrow(false);
        }
    }

    _enableGeometryEdges(refresh = false)
    {
        if (refresh === true) {
            this._disableMeshEdges();
        }

        if (this._geometryEdges === null) {
            this._geometryEdges = this._modelGenerator.getGeometryEdges();
            //this._scene.add(this._geometryEdges); ToDo: have _geometryEdges filled with actual data
        }
    }

    _disableGeometryEdges()
    {
        if (this._geometryEdges !== null) {
            //this._scene.remove(this._geometryEdges); ToDo: have _geometryEdges filled with actual data
            this._geometryEdges = null;
        }
    }

    _enableMeshEdges(refresh = false)
    {
        if (refresh === true) {
            this._disableMeshEdges();
        }
    
        if (this._meshEdges === null) {
            this._meshEdges = this._modelGenerator.getMeshEdges();
            this._scene.add(this._meshEdges);
            this._visualizationObjects.add(this._meshEdges.id);
        }
    }

    _disableMeshEdges()
    {
        if (this._meshEdges !== null) {
            this._scene.remove(this._meshEdges);
            this._meshEdges = null;
        }
    }

    _enableLegend()
    {
        if (true === this._legend) {
            this._disableLegend();
        }

        if (this._solutionObject !== null) {
            this._showColorLegend(this._solutionObject);
            this._legend = true;
        }
    }

    _disableLegend()
    {
        if (true === this._legend) {
            let legend = document.getElementById('color-legend');

            if (legend !== undefined) {
                legend.remove();
                this._legend = false;
            }
        }
    }

    _onPointerMove( event )
    {
        // must adjust when display frame is resizing
        this._pointer.x = ((event.clientX - 16) / this._width) * 2 - 1;
        this._pointer.y = -((event.clientY - 144) / this._height) * 2 + 1;

        if (this._fileType !== "geometry") { return}

        // Perform raycasting to find intersections
        const mesh = this._modelGenerator.getModel();
        if (mesh) {
            this._raycaster.setFromCamera(this._pointer, this._camera);
            const intersects = this._raycaster.intersectObject(mesh, true);

            if (intersects.length > 0) {
                const intersect = intersects[0];
                const faceIndex = intersect.faceIndex;

                if (faceIndex !== undefined && faceIndex !== null) {
                    const hoveredSrf =
                        this._meshObject.triangle_surfaces[faceIndex];
                    this._highlightSelection(hoveredSrf);
  
                }
            } else {
                this._clearHighlight();
            }
        }
    }

    _clearCache() {
        // Clear original colors cache
        this._modelGenerator.clearCache();
    }

    _resetSelection() {
        // Clear highlighted surfaces and reset selection
        this._hoveredSrf = null;
        this._selection = [];
        this._modelGenerator.highlightedSrfs = [];
        if (this._confirm_selection_btn) {
            this._confirm_selection_btn.style.display = 'none';
        }
    }

    _clearVisualizationObjects() {
        const objectsToKeep = new Set([this._camera.id, this._sceneLightComposer.getLightId()]);

        this._scene.children.slice().forEach(obj => {
            if (!objectsToKeep.has(obj.id)) {
                if (obj.geometry) {
                    obj.geometry.dispose();
                }
                if (obj.material) {
                    if (Array.isArray(obj.material)) {
                        obj.material.forEach(material => material.dispose());
                    } else {
                        obj.material.dispose();
                    }
                }
                this._scene.remove(obj);
            }
        });
    
        this._visualizationObjects.clear();
    }

    _selectPlane() {

        if (this._selection_allowed && this._hoveredSrf !== -1) {
            if (this._fileType === "geometry") {
                this._modelGenerator.toggleSurfaceSelection(this._hoveredSrf);
    
                if (this._selection.includes(this._hoveredSrf)) {
                    this._selection = this._selection.filter(index => index !== this._hoveredSrf);
                } else {
                    this._selection.push(this._hoveredSrf);
                }
    
                this._confirm_selection_btn.innerText = `Confirm Selection: ${JSON.stringify(this._selection)}`;
                this._confirm_selection_btn.style.display = this._selection.length > 0 ? 'block' : 'none';
            }
        }
    }

    _highlightSelection(hoveredSrf)
    {

        if (this._fileType !== "geometry") return;

        if (this._hoveredSrf !== hoveredSrf) {
            if (this._hoveredSrf !== -1 && !this._selection.includes(this._hoveredSrf)) {
                // Restore the previous hovered surface color
                this._modelGenerator.restoreSurfaceColor(this._hoveredSrf);
            }
    
            this._hoveredSrf = hoveredSrf;
            if (!this._selection.includes(this._hoveredSrf)) {
                this._modelGenerator.highlightSurface(this._hoveredSrf);
            }
            this._modelRenderer.animate(); // Ensure rendering is triggered after highlighting
        }
    }

    _clearHighlight() {
        if (this._hoveredSrf !== -1 && !this._selection.includes(this._hoveredSrf)) {
            this._modelGenerator.restoreSurfaceColor(this._hoveredSrf);
            this._hoveredSrf = -1;
            this._modelRenderer.animate(); // Ensure rendering is triggered after clearing highlight
        }
    }

    _addOverlays()
    {
        this._gui = new GUI({ autoPlace: false });
        this._viewPort = document.getElementById(this._container);
        this._confirm_selection_btn = document.createElement('div');

        this._confirm_selection_btn.classList.add('overlay-button');
        this._confirm_selection_btn.setAttribute('id', 'confirm_selection');
        this._confirm_selection_btn.innerText = 'Confirm Selection';
        // Add CSS to make the button fill its container horizontally
        this._confirm_selection_btn.style.width = '100%'; // Force the button to fill the container
        this._confirm_selection_btn.style.boxSizing = 'border-box'; // Ensure padding and border are included in the width
        this._confirm_selection_btn.style.display = 'none';
        this._confirm_selection_btn.addEventListener('click', (event) => {
            this._sendSelection();
        });

        this._viewPort.appendChild(this._confirm_selection_btn);
    }


    on(event, callback) {
        if (!this.listeners[event]) {
            this.listeners[event] = [];
        }
        this.listeners[event].push(callback);
    }

    _emit(event, data) {
        if (this.listeners[event]) {
            for (const callback of this.listeners[event]) {
                callback(data);
            }
        }
    }

    _sendSelection() {
        if (this.send_selection_enabled) {
            this._emit('selection', this._selection);
            this._selection = [];
            this._modelGenerator._highlightedSrfs = this._selection;
            this._confirm_selection_btn.style.display = 'none';
        }
    }

    _showColorLegend(solutionObject)
    {
        this._colorLegendCreator.setLegendConfiguration(solutionObject);
        this._viewPort.appendChild(this._colorLegendCreator.createLegend());
    }


    animate()
    {
        this._controls.update();
        this._modelRenderer.animate();
        this._mapRenderer.animate();
    }

    _combineCanvases(include_axis) {
        const combinedCanvas = document.createElement('canvas');
        const canvas1 = this._modelRenderer.getDomElement();
        const canvas2 = this._mapRenderer.getDomElement();
        combinedCanvas.width = canvas1.width;
        combinedCanvas.height = canvas1.height;


        const ctx = combinedCanvas.getContext('2d');
        ctx.drawImage(canvas1, 0, 0);
        if (include_axis) {
            ctx.drawImage(canvas2, 0, canvas1.height - canvas2.height);
        }
        return combinedCanvas;
    }

    takeScreenshot(include_axis) {
        this.animate();
        return this._combineCanvases(include_axis).toDataURL();
    }
}
